import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './Hashtag.module.scss';
import FooterOne from "./common/footers/footer-one";
import {svgFreeShipping, svgoffer, svgservice} from "../services/script";
import ModuleNewsletter from "./ModuleNewsletter";

class Hashtag extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Hammer | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."/>
                </Helmet>

                <section className={styles.hashtagSection}>
                    <h2>#ootdHAMMER</h2>
                    <p>
                        Share your personal style and see how others are styling their favourites from Hammer.<br/>Tag
                        your picture with @hammer_brand and #ootdHAMMER for a chance to be featured on hammer-clothing.com
                        and in our stores.
                    </p>
                    <div className={styles.hashtagPhotos}>
                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag01.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @hammer_brand
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag09.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @hammer_brand
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag06.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @hammer_brand
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag04.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @hammer_brand
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag02.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @hammer_brand
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag03.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @hammer_brand
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag07.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @hammer_brand
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag08.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @hammer_brand
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag05.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @hammer_brand
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>

                <ModuleNewsletter />

            </React.Fragment>
        );
    }
}

export default Hashtag;