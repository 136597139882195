import React, {Component} from 'react';
import {empty} from "../helpers/generalHelper";
import {message} from "antd";
import {apiCall} from "../helpers/networkHelper";
import {FORGOT_PASSWORD, SIGNIN} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {signIn} from "../actions";

class ForgotPassword extends Component {

    constructor (props) {
        super (props)

        this.state = {
            email: ''
        };
    }

    onChangeEmail = e => {
        this.setState({email: e.target.value});
    };

    onSubmitSignIn = e => {
        e.stopPropagation();
        e.preventDefault();

        const {
            email
        } = this.state;
        let validForm = true;

        if(empty(email)){
            message.error('Please enter your email address');
            validForm = false;
        }

        const formData ={
            email
        };

        if(validForm){
            apiCall(
                FORGOT_PASSWORD,
                'post',
                formData,
                (res) => {
                    this.setState({email: ''});
                    message.success('New password has been sent to your email.');
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                }
            );
        }

    };

    render (){
        const {email} = this.state;

        return (
            <div>
                {/*<Breadcrumb title={'ForgotPassword'}/>*/}

                {/*ForgotPassword section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Forgot Password</h3>
                                <div className="theme-card">
                                    <form className="theme-form" onSubmit={this.onSubmitSignIn}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="text"
                                                   className="form-control"
                                                   id="email"
                                                   placeholder="Email"
                                                   value={email}
                                                   onChange={this.onChangeEmail}
                                                   required={true} />
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-solid">Send New Password</button>
                                            <Link style={{float: "right"}} to={"/sign-in"}>Back to Sign In</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <h3>New Customer</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-font">Create an Account</h6>
                                    <p>Sign up for a free account at our store. Registration is quick and easy. It
                                        allows you to be able to order from our shop. To start shopping click
                                        register.</p>
                                    <Link to="/sign-up" className="btn btn-solid">Sign Up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated
});

export default connect(
    mapStateToProps,
    {signIn}
)(ForgotPassword)