import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Breadcrumb from "../common/breadcrumb";
import NewProduct from "../common/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import StickyBox from "react-sticky-box";
import styles from './CollectionLeftSidebar.module.scss'
import {empty, getUrlParam} from "../../helpers/generalHelper";
import {apiCall} from "../../helpers/networkHelper";
import {MASTER_CATEGORY, MASTER_CATEGORY_SUB} from "../../utils/endpoints";

class CollectionLeftSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            layoutColumns: 3,
            productCount: 0,
            category_id: getUrlParam('category_id'),
            category_sub_id: getUrlParam('category_sub_id'),
            coverImage: null,
            coverTitle: 'Our Collections',
            coverText: 'Your T-shirts are one way to show the world what you stand for and who you are',
            sale: getUrlParam('sale'),
            sort: getUrlParam('sort') || 'PRICE_DESC',
            search: getUrlParam('search')
        };
    }

    componentDidMount() {
        const {category_id, category_sub_id} = this.state;
        if (!empty(category_sub_id)) {
            apiCall(
                MASTER_CATEGORY_SUB + `?id=${category_sub_id}`,
                'get',
                null,
                res => {
                    const categorySub = res.data.category_sub;
                    if (!empty(categorySub)) {
                        if (categorySub.show_cover === 1) {
                            this.setState({
                                coverImage: categorySub.cover_image_url,
                                coverText: categorySub.cover_text,
                                coverTitle: categorySub.name
                            });
                        } else {
                            this.setState({
                                coverImage: categorySub.category.cover_image_url,
                                coverText: categorySub.category.cover_text,
                                coverTitle: categorySub.category.name
                            });
                        }
                    }
                }
            );
        }

        if (!empty(category_id)) {
            apiCall(
                MASTER_CATEGORY + `?id=${category_id}`,
                'get',
                null,
                res => {
                    const category = res.data.category;
                    if (!empty(category)) {
                        this.setState({
                            coverImage: category.cover_image_url,
                            coverText: category.cover_text,
                            coverTitle: category.name
                        });
                    }
                }
            );
        }
    }

    onLayoutViewClicked = (columns) => {
        this.setState({
            layoutColumns: columns
        });
    };

    setProductCount = (productCount) => {
        this.setState({
            productCount
        });
    };

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    };

    render() {
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Hammer | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."/>
                </Helmet>
                {/*SEO Support End */}

                {/*<Breadcrumb title={'Collection'}/>*/}

                {!empty(this.state.coverImage) && (
                    <div className={styles.cover}>
                        <img src={this.state.coverImage} alt={this.state.coverTitle}/>
                    </div>
                )}

                <h1 className={styles.coverTitle}>{this.state.coverTitle}</h1>

                <p className={styles.coverDesc}>
                    {this.state.coverText}
                </p>

                <section className={`section-b-space ${styles.collectionSection}`}>
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-3 collection-filter">

                                    <StickyBox offsetTop={80} offsetBottom={20}>
                                        <div>
                                            <Filter/>
                                            {/*<NewProduct/>*/}
                                            {/*<div className="collection-sidebar-banner">
                                                <a href="#">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/side-banner.png`}
                                                         className="img-fluid" alt=""/>
                                                </a>
                                            </div>*/}
                                        </div>
                                    </StickyBox>
                                    {/*side-bar banner end here*/}
                                </div>
                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    {/*<div className="top-banner-wrapper">
                                                        <a href="#"><img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`}
                                                            className="img-fluid" alt=""/></a>
                                                        <div className="top-banner-content small-section">
                                                            <h4>fashion</h4>
                                                            <h5>Lorem Ipsum is simply dummy text of the printing and
                                                                typesetting industry.</h5>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and
                                                                typesetting industry. Lorem Ipsum has been the
                                                                industry's standard dummy text ever since the 1500s,
                                                                when an unknown printer took a galley of type and
                                                                scrambled it to make a type specimen book. It has
                                                                survived not only five centuries, but also the leap into
                                                                electronic typesetting, remaining essentially unchanged.
                                                                It was popularised in the 1960s with the release of
                                                                Letraset sheets containing Lorem Ipsum passages, and
                                                                more recently with desktop publishing software like
                                                                Aldus PageMaker including versions of Lorem Ipsum. </p>
                                                        </div>
                                                    </div>*/}
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter" style={{
                                                            borderTop: 0
                                                        }}>
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-xl-12">
                                                                        <div className={styles.filterActions}>
                                                                            <div
                                                                                className={`filter-main-btn ${styles.filterBtn}`}>
                                                                                <span onClick={this.openFilter}
                                                                                      className="filter-btn btn btn-theme">
                                                                                    {/*<i className="fa fa-filter"
                                                                                       aria-hidden="true"/> Filter*/}
                                                                                       Categories
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                className={`product-page-filter ${styles.sortingMobile}`}>
                                                                                <select onChange={(e) => {
                                                                                    let newUrl = new URL(window.location.href);
                                                                                    if (newUrl.searchParams.has('sort')) {
                                                                                        newUrl.searchParams.delete('sort');
                                                                                    }
                                                                                    newUrl.searchParams.append('sort', e.target.value);
                                                                                    window.location.href = newUrl.href;
                                                                                }}>
                                                                                    <option
                                                                                        selected={this.state.sort === 'NEWEST'}
                                                                                        value="NEWEST">Newest Items
                                                                                    </option>
                                                                                    <option
                                                                                        selected={this.state.sort === 'PRICE_DESC' || empty(this.state.sort)}
                                                                                        value="PRICE_DESC">Highest Price
                                                                                    </option>
                                                                                    <option
                                                                                        selected={this.state.sort === 'PRICE_ASC'}
                                                                                        value="PRICE_ASC">Lowest Price
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        {/*<FilterBar
                                                                            onLayoutViewClicked={(columns) => this.onLayoutViewClicked(columns)}
                                                                            productCount={this.state.productCount}
                                                                        />*/}

                                                                        <div className="product-filter-content">
                                                                            <div
                                                                                className={`search-count ${styles.searchResultText}`}
                                                                                style={{border: 0}}>
                                                                                <h5>Found {this.state.productCount} Items</h5>
                                                                            </div>
                                                                            <div
                                                                                className={`product-page-filter ${styles.sorting}`}>
                                                                                <select onChange={(e) => {
                                                                                    let newUrl = new URL(window.location.href);
                                                                                    if (newUrl.searchParams.has('sort')) {
                                                                                        newUrl.searchParams.delete('sort');
                                                                                    }
                                                                                    newUrl.searchParams.append('sort', e.target.value);
                                                                                    window.location.href = newUrl.href;
                                                                                }}>
                                                                                    <option
                                                                                        selected={this.state.sort === 'NEWEST'}
                                                                                        value="NEWEST">Newest Items
                                                                                    </option>
                                                                                    <option
                                                                                        selected={this.state.sort === 'PRICE_DESC'}
                                                                                        value="PRICE_DESC">Price: High
                                                                                        to Low
                                                                                    </option>
                                                                                    <option
                                                                                        selected={this.state.sort === 'PRICE_ASC'}
                                                                                        value="PRICE_ASC">Price: Low to
                                                                                        High
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Products Listing Component*/}
                                                        <ProductListing colSize={this.state.layoutColumns}
                                                                        setProductCount={productCount => this.setProductCount(productCount)}
                                                                        categoryId={this.state.category_id}
                                                                        categorySubId={this.state.category_sub_id}
                                                                        sort={this.state.sort}
                                                                        search={this.state.search}
                                                                        sale={this.state.sale}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default CollectionLeftSidebar;