import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './PrivacyPolicy.module.scss';
import ModuleNewsletter from "./ModuleNewsletter";

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Hammer | PT Warna Mardhika</title>
                    <meta name="description"
                          content="HAMMER was launched as the first brand of PT WARNA MARDHIKA in 1987. With characteristic lines, varying colors and bold, making HAMMER very famous among celebrities, models, and Indonesians."/>
                </Helmet>

                <section className={styles.titleSection}>
                    <h1 className={styles.title}>Privacy Policy</h1>
                    <p className={styles.subtitle}>Kebijakan Privasi</p>
                </section>

                <section className={`container ${styles.tncSection}`}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={styles.tncItem}>
                                BACALAH SELURUH KEBIJAKAN PRIVASI INI SEBELUM MEMBERIKAN PERSETUJUAN
                                DAN MENYERAHKAN DATA PRIBADI ANDA ATAS LAYANAN KAMI.<br/>
                                <br/>
                                Kebijakan Privasi berikut menjelaskan bagaimana Kami, PT WARNAMARDHIKA dan/atau
                                afiliasi nya mengumpulkan, menyimpan, menggunakan, mengolah, menguasai, mentransfer,
                                mengungkapkan dan melindungi data pribadi (“Data Pribadi”) Anda selaku Pengguna atas
                                Platform Kami, sebagai bentuk kepedulian Kami dengan privasi Anda dan kami berkomitmen
                                untuk melindungi Data Pribadi Anda. Kebijakan Privasi ini berlaku untuk semua Pengguna
                                Platform Kami, kecuali diatur dalam kebijakan privasi terpisah.<br/>
                                <br/>
                                Kami ingin memberikan pengalaman terbaik bagi Anda untuk dapat menikmati layanan yang
                                Kami berikan. Oleh karenanya, kami perlu mengenal Anda lebih jauh dengan menyimpan dan
                                mengelola Data Pribadi Anda. Kebijakan Privasi ini akan memberitahu Anda bagaimana dan
                                mengapa Kami mengumpulkan, menyimpan, menggunakan, melindungi Data Pribadi dan hak
                                privasi Anda, serta bagaimana tanggung jawab Kami sehubungan dengan proses
                                pengumpulan dan pengelolaan Data Pribadi Anda.<br/>
                                <br/>
                                Kebijakan Privasi ini menjelaskan secara rinci mengenai tata cara pengumpulan,
                                penyimpanan dan pengelolaan Data Pribadi Anda oleh Kami. Tujuan dari Kebijakan Privasi ini
                                adalah untuk:<br/>
                                <br/>
                                • Memastikan Anda memahami dan menjelaskan cara kami menggunakan Data Pribadi apa
                                saja yang Kami kumpulkan, alasan mengapa Kami mengumpulkan, menggunakan,
                                mengelola dan menyimpannya dan kepada siapa Kami bekerjasama untuk mengelola Data
                                Pribadi Anda tersebut ketika menggunakan platform kami, dan;<br/>
                                <br/>
                                • Menjelaskan hak dan pilihan yang Anda miliki terkait dengan Data Pribadi Anda yang Anda
                                sampaikan kepada Kami serta bagaimana Kami akan melindungi Data Pribadi Anda.
                                Kami berharap bahwa Kebijakan Privasi ini dapat membantu Anda memahami komitmen
                                Kami dalam melindungi Data Pribadi Anda. Apabila Anda membutuhkan penjelasan atau
                                memiliki pertanyaan lebih lanjut, Kami dengan senang hati melayani Anda dengan merujuk
                                pada bagian “Hubungi Kami” dalam kebijakan Privasi ini. Apabila Anda tidak menyetujui isi
                                dari Kebijakan Privasi ini, Anda memiliki pilihan untuk menggunakan atau tidak menggunakan
                                layanan Kami.<br/>
                                <br/>
                                Dalam Kebijakan Privasi ini:<br/>
                                Layanan adalah layanan sebagaimana didefinisikan dalam Syarat dan Ketentuan.
                                Platform berarti situs web, aplikasi seluler, situs seluler, atau properti online lainnya dimana
                                Kami menawarkan Layanan Kami.<br/>
                                <br/>
                                1. Persetujuan<br/>
                                <br/>
                                Harap baca pernyataan privasi ini dengan teliti sebelum menggunakan Layanan Kami.
                                Dengan menyetujui, mendaftar, mengakses dan/atau menggunakan Platform Kami, Anda,
                                wali Anda, atau pihak yang Anda wakili mengakui telah membaca dan memahami Kebijakan
                                Privasi ini dan menyetujui segala ketentuannya. Secara khusus, Anda setuju dan memberikan
                                persetujuan kepada Kami untuk mengumpulkan, menggunakan, membagikan,
                                mengungkapkan, menyimpan, mentransfer, atau mengolah Data Pribadi Anda sesuai dengan
                                Kebijakan Privasi ini serta Anda mengonfirmasi bahwa seluruh Data Pribadi yang Anda
                                informasikan kepada Kami adalah benar dan akurat.<br/>
                                <br/>
                                Dalam keadaan di mana Anda menyediakan Data Pribadi yang berkaitan dengan individu lain
                                (seperti Data Pribadi yang berkaitan dengan pasangan Anda, anggota keluarga, teman, atau
                                pihak lain), dan/atau dokumen-dokumen badan hukum lain, Anda menyatakan dan menjamin
                                bahwa Anda telah memperoleh persetujuan dari individu atau badan hukum tersebut untuk,
                                dan dengan ini menyetujui atas nama individu atau badan hukum tersebut untuk,
                                pengumpulan, penggunaan, pengungkapan dan pengolahan Informasi Pribadi mereka oleh
                                Kami.<br/>
                                <br/>
                                Anda dapat menarik persetujuan Anda untuk setiap atau segala pengumpulan, penggunaan
                                atau pengungkapan Data Pribadi Anda kapan saja dengan memberikan kepada Kami
                                pemberitahuan yang wajar secara tertulis menggunakan rincian kontak yang disebutkan di
                                bawah ini. Anda harus memahami dan mengakui bahwa setelah penarikan persetujuan
                                tersebut, Anda tidak lagi dapat menggunakan Platform Kami. Penarikan persetujuan Anda
                                dapat mengakibatkan penghentian akun profil yang Anda buat untuk mengakses Platform
                                Kami (“Akun”) Anda atau hubungan kontraktual Anda dengan Kami, dengan semua hak dan
                                kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah menerima pemberitahuan untuk
                                menarik persetujuan untuk pengumpulan, penggunaan atau pengungkapan Informasi Pribadi
                                Anda, Kami akan menginformasikan kepada Anda tentang konsekuensi yang mungkin terjadi
                                dari penarikan tersebut sehingga Anda dapat memutuskan apakah Anda tetap ingin menarik
                                persetujuan.<br/>
                                <br/>
                                2. Ringkasan Kebijakan Privasi<br/>
                                <br/>
                                Kami mengumpulkan Data Pribadi Anda ketika Anda:<br/>
                                <br/>
                                1.Memberikan informasi disediakan melalui formulir kontak pada saat Anda menggunakan
                                layanan dan Platform Kami; dan<br/>
                                <br/>
                                2. Ketika Anda berinteraksi dengan Kami melalui Surat Elektronik (e-mail), media sosial, pusat
                                kontak Kami atau saluran dan cara lain manapun baik dalam jaringan/daring (online) atau luar
                                jaringan (offline).
                                Kami juga mungkin menerima Data Pribadi Anda dari pihak lain dalam afiliasi kami, mitra,
                                vendor/pemasok, agen, dan pihak-pihak yang bekerjasama dengan Kami dalam
                                menyediakan Platform Kami, sponsor-sponsor diskon (apabila ada), penerima lisensi, dan
                                entitas lainnya, dimana terbatas sesuai dengan tujuan Kebijakan Privasi ini.<br/>
                                <br/>
                                3. Data yang kami kumpulkan secara otomatis saat Anda menggunakan Layanan Kami
                                Ketika Anda berinteraksi dengan Platform Kami atau menggunakan Layanan Kami, Kami
                                secara otomatis mengumpulkan informasi berikut tentang Anda:<br/>
                                <br/>
                                Informasi lokasi<br/>
                                <br/>
                                Tergantung pada izin perangkat Anda, jika Anda memilih untuk menemukan pusat inspeksi
                                terdekat, Kami secara otomatis mengumpulkan dan memproses informasi tentang lokasi
                                Anda yang sebenarnya. Kami menggunakan berbagai teknologi untuk menentukan lokasi,
                                termasuk alamat IP, GPS, titik akses Wi-Fi dan menara seluler. Data lokasi Anda
                                memungkinkan Anda melihat pusat inspeksi di dekat Anda dan membantu menyarankan
                                pusat inspeksi di dekat Anda.<br/>
                                <br/>
                                4. Cookies dan Teknologi Sejenis<br/>
                                <br/>
                                Kami menggunakan cookies untuk mengelola sesi pengguna Kami, untuk menyimpan pilihan
                                bahasa preferensi Anda dan memberikan Anda iklan yang relevan. "Cookies" adalah file teks
                                kecil yang ditransfer oleh server web ke hard drive perangkat Anda. Cookies dapat digunakan
                                untuk mengumpulkan tanggal dan waktu kunjungan Anda, riwayat penelusuran Anda,
                                preferensi Anda, dan nama pengguna Anda. Anda dapat mengatur browser Anda untuk
                                menolak semua atau beberapa cookies, atau untuk mengingatkan Anda ketika situs web
                                mengatur atau mengakses cookies.<br/>
                                <br/>
                                Kami mungkin menempatkan, melihat, atau menggunakan “Cookie”, log server web, web
                                beacon, tag piksel, atau alat elektronik lainnya untuk mengumpulkan informasi statistik dan
                                informasi lainnya tentang penggunaan Platform Kami. Kami (termasuk pihak yang
                                bekerjasama dengan Kami, sebagaimana Kami jelaskan dalam Kebijakan Privasi ini) dapat
                                menggunakan informasi yang dikumpulkan untuk tujuan sebagaimana Kami jelaskan dalam
                                Kebijakan Privasi ini atau sejauh yang diperbolehkan oleh peraturan perundang-undangan
                                yang berlaku, termasuk untuk perubahan operasional, riset, analisis, dan untuk melayani
                                pengunjung Platform Kami secara lebih baik (seperti mengaktifkan log-in, dengan
                                menyediakan akses ke bagian tertentu di aplikasi, platform atau layanan Kami, menyediakan
                                konten yang disesuaikan, atau menampilkan iklan online yang disesuaikan untuk Anda atau
                                minat Anda, untuk menghimpun informasi gabungan dan anonim tentang penggunaan
                                aplikasi, platform atau layanan Kami.<br/>
                                <br/>
                                Jika Anda menonaktifkan atau menolak cookies, harap dicatat bahwa beberapa bagian dari
                                Layanan/Platform kami mungkin menjadi tidak dapat diakses atau mungkin tidak berfungsi
                                dengan baik<br/>
                                <br/>
                                5. Mengapa kami memproses informasi pribadi Anda?<br/>
                                <br/>
                                Kami hanya akan menggunakan data pribadi Anda ketika hukum mengizinkan Kami untuk
                                melakukannya. Secara umum, Kami akan menggunakan data pribadi Anda dalam keadaan
                                berikut:<br/>
                                <br/>
                                • Ketika kepentingan pengesahan Kami diperlukan untuk meningkatkan layanan Kami dan
                                untuk memberikan Anda Platform yang aman dan nyaman.<br/>
                                <br/>
                                • Dimana Kami perlu mematuhi kewajiban hukum atau peraturan yang berlaku.
                                Dalam keadaan tertentu, Kami juga dapat memproses data pribadi Anda berdasarkan
                                persetujuan Anda. Jika Kami melakukan ini, Kami akan memberitahu Anda tujuan dan
                                kategori data pribadi yang akan diproses pada saat Kami meminta persetujuan Anda.
                                Kami telah menetapkan di bawah ini deskripsi cara Kami menggunakan data pribadi Anda dan
                                dasar hukum yang Kami andalkan untuk melakukannya. Kami juga telah mengidentifikasi apa
                                kepentingan sah Kami.<br/>
                                <br/>
                                6. Jika Anda menyediakan Informasi Pribadi yang tidak lengkap<br/>
                                <br/>
                                Kami membutuhkan bantuan Anda untuk memastikan bahwa Informasi Pribadi Anda terkini,
                                lengkap dan akurat. Karena itu, harap beri tahu Kami tentang perubahan pada Informasi
                                Pribadi Anda mengirimkan rincian Anda yang diperbarui kepada Kami. Apabila Anda tidak
                                menyediakan Informasi Pribadi secara benar atau tidak lengkap saat Kami minta, maka Kami
                                mungkin akan meminta Anda untuk melakukan perbaikan dan/atau melengkapi Informasi
                                Pribadi tersebut. Selama Informasi Pribadi Anda belum diperbaiki dan/atau dilengkapi, Anda
                                mungkin belum dapat menggunakan layanan pada Platform Kami secara optimal. Anda
                                memahami bahwa kebenaran atas Informasi Pribadi yang Anda sampaikan kepada Kami
                                sepenuhnya merupakan tanggung jawab Anda, karenanya Anda setuju untuk selalu
                                membebaskan Kami dari setiap tuntutan, dan/atau kerugian akibat dari ketidaklengkapan
                                dan/atau ketidakbenaran Informasi Rahasia yang Anda sediakan kepada Kami.<br/>
                                <br/>
                                7. Dimana Kami memproses Informasi Pribadi Anda?<br/>
                                <br/>
                                Informasi Pribadi Anda disimpan secara aman dan dapat diakses di berbagai lokasi serta
                                dapat memanfaatkan layanan dan penyimpanan cloud. Server Kami mungkin berlokasi di luar
                                wilayah teritorial Republik Indonesia (“Wilayah”). Kami juga dapat mentransfer informasi Anda
                                pada Afiliasi Kami dan pemasok pihak ketiga serta mitra di luar Wilayah untuk tujuan
                                sebagaimana ditetapkan dalam Kebijakan Privasi ini<br/>
                                <br/>
                                8. Sampai Kapan Kami menyimpan Informasi Pribadi Anda?<br/>
                                <br/>
                                Kami menyimpan informasi Anda selama diperlukan untuk memenuhi tujuan
                                pengumpulannya, atau sebagaimana diwajibkan oleh Peraturan Perundang-undangan yang
                                Berlaku.<br/>
                                <br/>
                                Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Informasi Pribadi Anda
                                disimpan oleh pihak lain dengan termasuk institusi pemerintah cara tertentu. Dalam hal Kami
                                membagikan Informasi Pribadi Anda kepada institusi pemerintah yang berwenang dan/atau
                                institusi lainnya yang dapat ditunjuk oleh pemerintah yang berwenang atau memiliki kerja
                                sama dengan Kami, Anda menyetujui dan mengakui bahwa penyimpanan Informasi Pribadi
                                Anda oleh institusi terkait akan mengikuti kebijakan penyimpanan data masing-masing
                                institusi tersebut. Sepanjang diizinkan oleh Peraturan Perundang-undangan yang Berlaku,
                                Kami tidak akan bertanggung jawab atas penyimpanan Informasi Pribadi Anda yang bocor
                                karena kelalaian atau ketidak hati-hatian Anda sendiri. Anda setuju untuk mengganti rugi,
                                membela, dan membebaskan Kami, pejabat, direktur, karyawan, agen, mitra, pemasok,
                                kontraktor, dan afiliasi Kami dari dan terhadap setiap dan segala klaim, kerugian, kewajiban,
                                biaya, kerusakan, dan ongkos (termasuk tetapi tidak terbatas pada biaya hukum dan
                                pengeluaran biaya ganti rugi penuh) yang dihasilkan secara langsung atau tidak langsung
                                dari setiap penyimpanan yang tidak sah atas Informasi Pribadi Anda atau akibat kelalaian
                                Anda sendiri.<br/>
                                <br/>
                                9. Bagaimana pemberitahuan terkait perubahan Kebijakan Privasi Kami?<br/>
                                <br/>
                                Dalam memberikan layanan terbaik kepada Anda serta dalam memberikan perlindungan dan
                                kepatuhan hukum, Kami selalu berupaya untuk mematuhi seluruh ketentuan peraturan
                                perundang-undangan yang berlaku, termasuk terkait dengan kerahasiaan atas Informasi
                                Pribadi yang Anda sampaikan melalui Platform Kami. Kami dapat mengubah Kebijakan
                                Privasi ini dari waktu ke waktu. Kami akan menampilkan setiap perubahan pada Kebijakan
                                Privasi melalui Platform Kami dengan cap tanggal, sehingga Anda dapat mengetahui kapan
                                Kebijakan Privasi terakhir diperbaharui dan Kami email untuk setiap perubahan Kebijakan
                                Privasi ini. Anda dapat melakukan pemeriksaan secara berkala untuk melihat pembaruan
                                atau perubahan apapun pada Kebijakan Privasi ini. Kebijakan Privasi ini tunduk pada
                                peraturan perundang-undangan dimana Kami berdomisili.<br/>
                                <br/>
                                10. Penggunaan Informasi Pribadi<br/>
                                <br/>
                                Kami dapat menggunakan Informasi Pribadi yang dikumpulkan untuk tujuan sebagai berikut
                                maupun untuk tujuan lain yang diizinkan oleh Peraturan Perundang-undangan yang Berlaku
                                dan yang dibutuhkan berkaitan pelaksanaan Lelang dan/atau layanan lainnya yang dapat
                                dinikmati oleh Anda melalui Platform Kami ("Tujuan"):<br/>
                                <br/>
                                Kami dapat menggunakan Informasi Pribadi Anda:<br/>
                                <br/>
                                • untuk mengidentifikasi dan mendaftarkan Anda sebagai Pengguna dan untuk
                                mengadministrasikan, memverifikasi, menonaktifkan, memblokir atau mengelola Akun Anda
                                pada Platform Kami;<br/>
                                <br/>
                                • Memungkinkan anggota Anda untuk masuk menggunakan akun Anda pada platform lain
                                yang dimiliki oleh Kami;<br/>
                                <br/>
                                • untuk memfasilitasi atau memungkinkan verifikasi apapun yang menurut pertimbangan Kami
                                diperlukan sebelum Penyedia Layanan menyediakan layanan kepada Anda atau sebelum
                                Kami mendaftarkan Anda sebagai Pengguna, termasuk melakukan proses Mengenal
                                Pelanggan;<br/>
                                <br/>
                                • untuk memungkinkan Anda menikmati layanan Kami melalui Platform Kami;<br/>
                                <br/>
                                • untuk berkomunikasi dengan Anda dan mengirimkan Anda informasi sehubungan dengan
                                penggunaan Platform Kami;<br/>
                                <br/>
                                • untuk memberitahu Anda mengenai segala pembaruan pada Platform Kami atau perubahan
                                pada layanan yang disediakan;<br/>
                                <br/>
                                • untuk mengolah dan menanggapi pertanyaan dan saran yang diterima dari Anda;<br/>
                                <br/>
                                • untuk melakukan pemeliharaan, mengembangkan, menguji, meningkatkan, dan
                                mempersonalisasikan Platform Kami untuk memenuhi kebutuhan dan preferensi Anda
                                sebagai Pengguna;<br/>
                                <br/>
                                • untuk memantau dan menganalisis aktivitas, perilaku, dan data demografis pengguna
                                termasuk kebiasaan dan penggunaan berbagai layanan yang tersedia di Platform Kami;<br/>
                                <br/>
                                • untuk menawarkan atau menyediakan layanan dari orang atau perusahaan lain yang
                                mengendalikan, dikendalikan oleh, atau berada dibawah pengendalian bersama dengan
                                Kami (“Afiliasi”) atau mitra Kami (sebagaimana berlaku);<br/>
                                <br/>
                                • untuk mengirimkan Anda komunikasi pemasaran, iklan, voucher, survei, dan informasi
                                secara langsung atau terfokus, dan informasi tentang penawaran atau promosi khusus; dan
                                untuk memperbaharui atau mengembangkan Platform Kami; dan<br/>
                                <br/>
                                • Memberi tahu Anda tentang perubahan layanan Kami yang tersedia pada Platform Kami.
                                Kami juga dapat menggunakan Informasi Pribadi Anda secara lebih umum untuk
                                tujuan-tujuan sebagai berikut (walaupun dalam kasus tertentu Kami akan bertindak secara
                                wajar dan tidak menggunakan Informasi Pribadi Anda lebih dari apa yang diperlukan untuk
                                tujuan tersebut):<br/>
                                <br/>
                                • untuk melakukan proses dan fungsi bisnis terkait;<br/>
                                <br/>
                                • untuk memantau penggunaan Platform Kami dan mengelola, mendukung serta
                                meningkatkan efisiensi kinerja, perkembangan, pengalaman pengguna dan fungsi-fungsi
                                Platform Kami;<br/>
                                <br/>
                                • untuk menyediakan bantuan sehubungan dengan dan untuk menyelesaikan kesulitan teknis
                                atau masalah operasional dengan Platform Kami atau layanan;<br/>
                                <br/>
                                • untuk menghasilkan informasi statistik dan data analitik untuk tujuan pengujian, penelitian,
                                analisis, pengembangan produk, kemitraan komersial, dan kerja sama;<br/>
                                <br/>
                                • untuk mencegah, mendeteksi dan menyelidiki segala kegiatan yang dilarang, ilegal, tidak
                                sah,<br/>
                                <br/>
                                • untuk memungkinkan Kami mematuhi semua kewajiban berdasarkan Peraturan
                                Perundang-undangan yang Berlaku, termasuk namun tidak terbatas pada menanggapi
                                permintaan, penyelidikan, atau arahan peraturan, mematuhi persyaratan pengarsipan,
                                pelaporan, dan perizinan yang disyaratkan oleh hukum atau peraturan yang berlaku dan
                                melakukan pemeriksaan audit, uji tuntas dan penyelidikan;<br/>
                                <br/>
                                • untuk tujuan lain yang Kami beritahukan kepada Anda, dan apabila disyaratkan oleh
                                Peraturan Perundang-undangan yang Berlaku;<br/>
                                <br/>
                                • Memproses partisipasi Anda dalam setiap kegiatan produksi, kontes, permainan, promosi,
                                jajak pendapat maupun survei; dan<br/>
                                <br/>
                                • Proses administrasi internal seperti audit dan perpajakan.<br/>
                                <br/>
                                11. Pengungkapan Informasi Pribadi Anda<br/>
                                <br/>
                                Dengan persetujuan Anda atas Kebijakan Privasi dan/atau jika diwajibkan oleh
                                undang-undang yang berlaku, Kami dapat mengungkapkan memberikan akses atau
                                membagikan Informasi Pribadi Anda dengan afiliasi dan pihak lain untuk tujuan sebagai
                                berikut ini serta untuk tujuan lain yang diizinkan oleh Peraturan Perundang-undangan yang
                                Berlaku:<br/>
                                <br/>
                                • memungkinkan Kami untuk melakukan atau memberikan layanan Kami melalui Platform
                                Kami, termasuk menghubungi Anda;<br/>
                                <br/>
                                • jika disyaratkan atau diotorisasikan oleh Peraturan Perundang-undangan yang Berlaku
                                (termasuk namun tidak terbatas pada menanggapi pertanyaan terkait regulasi, penyelidikan
                                atau pedoman, atau mematuhi persyaratan atau ketentuan pengarsipan dan pelaporan
                                berdasarkan undang-undang), untuk tujuan yang ditentukan dalam Peraturan
                                Perundang-undangan yang Berlaku;<br/>
                                <br/>
                                • jika diinstruksikan, diminta, disyaratkan atau diperbolehkan oleh pemerintah yang
                                berwenang, untuk tujuan sebagaimana disebutkan dalam kebijakan pemerintah, peraturan
                                atau Peraturan Perundang-undangan yang Berlaku;<br/>
                                <br/>
                                • jika terdapat proses hukum dalam bentuk apapun antara Anda dengan Kami, atau antara
                                Anda dengan pihak lain, sehubungan dengan, atau terkait dengan layanan, untuk keperluan
                                proses hukum tersebut;<br/>
                                <br/>
                                • sehubungan dengan segala proses verifikasi Kami atau pihak ketiga perlukan sebelum
                                Penyedia Layanan menyediakan layanan kepada Anda atau Kami mendaftarkan Anda
                                sebagai pengguna, termasuk proses KYC (Know Your Customer) (sebagaimana berlaku);<br/>
                                <br/>
                                • saat layanan Kami tersedia di platform pihak ketiga manapun, (i) untuk membantu Kami
                                memperoleh Informasi Pribadi Anda dan/atau (ii) mendaftarkan Anda atau membantu Anda
                                menggunakan layanan Kami pada platform pihak ketiga tersebut;<br/>
                                <br/>
                                • dalam keadaan darurat terkait kesehatan atau keselamatan Anda (baik Anda adalah
                                Pengguna atau Penyedia Layanan) untuk keperluan menangani keadaan darurat tersebut;<br/>
                                <br/>
                                • sehubungan dengan, penggabungan, penjualan aset perusahaan, konsolidasi atau
                                restrukturisasi, pembiayaan atau akuisisi semua atau sebagian dari bisnis Kami oleh atau ke
                                perusahaan lain, untuk keperluan transaksi tersebut (bahkan jika kemudian transaksi tidak
                                dilanjutkan);<br/>
                                <br/>
                                • kepada pihak ketiga (termasuk agen, vendor, pemasok, kontraktor, mitra, dan pihak lain
                                yang menyediakan layanan kepada Kami atau Anda, melakukan tugas atas nama Kami, atau
                                pihak dengan siapa Kami mengadakan kerja sama komersial), atau untuk kebutuhan
                                pemberian layanan yang Anda nikmati melalui Platform Kami, untuk atau sehubungan dengan
                                tujuan di mana pihak ketiga tersebut terlibat, untuk melaksanakan pengungkapan kepada
                                pihak ketiga terkait yang secara teknis diperlukan untuk memproses transaksi Anda atau
                                tujuan kerja sama Kami dengan pihak ketiga tersebut (tergantung keadaannya), yang dapat
                                mencakup diperbolehkannya pihak ketiga tersebut untuk memperkenalkan atau menawarkan
                                produk atau layanan kepada Anda, melakukan autentikasi terhadap Anda atau
                                menghubungkan Anda dengan Akun atau melakukan kegiatan lain termasuk pemasaran,
                                penelitian, analisis dan pengembangan produk;<br/>
                                <br/>
                                • menanggapi pertanyaan, permintaan, atau umpan balik Anda;<br/>
                                <br/>
                                • kepada penyedia analitik dan mesin pencari yang membantu kami dalam peningkatan dan
                                optimalisasi situs web dan aplikasi seluler;<br/>
                                <br/>
                                • dalam hal Kami berbagi Informasi Pribadi dengan afiliasi, Kami akan melakukannya dengan
                                maksud agar mereka membantu Kami dalam menyediakan Platform Kami, untuk
                                mengoperasikan bisnis Kami (termasuk, ketika Anda berlangganan milis Kami, untuk tujuan
                                pemasaran langsung), atau untuk tujuan pengolahan data atas nama kami. Semua afiliasi
                                Kami berkomitmen untuk mengolah Informasi Pribadi yang mereka terima dari Kami sesuai
                                dengan Kebijakan Privasi dan Peraturan Perundang-undangan yang Berlaku ini. Kami tidak
                                akan menjual atau menyewakan Informasi Pribadi Anda.<br/>
                                <br/>
                                12. Selain sebagaimana diatur dalam Kebijakan Privasi ini, Kami dapat mengungkapkan dan
                                membagikan Informasi Pribadi Anda jika Kami memberitahu Anda dan Kami telah
                                memperoleh persetujuan Anda untuk pengungkapan atau pembagian tersebut.
                                Kami juga akan mengungkapkan Informasi Pribadi Anda kepada pihak ketiga:<br/>
                                <br/>
                                • dalam hal kami menjual atau membeli bisnis atau aset apa pun, dalam hal ini kami dapat
                                mengungkapkan data Anda kepada calon penjual atau pembeli bisnis atau aset tersebut;<br/>
                                <br/>
                                • jika kami atau secara substansial semua aset kami diakuisisi oleh pihak ketiga, dalam hal ini
                                Data Pelanggan yang kami pegang tentang pelanggan kami akan menjadi salah satu aset
                                yang dialihkan;<br/>
                                <br/>
                                • kami dapat mengungkapkan Informasi Rahasia kepada penasihat hukum kami untuk
                                menetapkan, menjalankan, atau membela hak hukum kami, kepada penasihat profesional
                                kami yang lain, atau sebagaimana diizinkan atau diwajibkan oleh hukum;<br/>
                                <br/>
                                • Kami juga berhak untuk membagikan Informasi Pribadi sebagaimana diperlukan untuk
                                mencegah ancaman terhadap kehidupan, kesehatan, atau keamanan individu atau entitas
                                perusahaan; atau<br/>
                                <br/>
                                • Kami akan mengungkapkan Informasi Pribadi, sebagaimana diperlukan, untuk menyelidiki
                                dugaan kegiatan yang melanggar hukum termasuk namun tidak terbatas pada penipuan,
                                pelanggaran kekayaan intelektual, atau privasi.<br/>
                                <br/>
                                13. Transfer Dan Penggunaan dari Internasional<br/>
                                <br/>
                                Saat Anda menggunakan Platform Kami dan layanan Kami di negara lain dimana Platform
                                Kami dapat diakses (“Negara Tujuan”), Kami dapat mentransfer Informasi Pribadi Anda dari
                                negara asal (“Negara Asal”) ke Negara Tujuan untuk membuat Anda dapat mengakses dan
                                memastikan pengalaman pelanggan yang lancar ketiga Anda menggunakan Platform Kami di
                                luar negeri. Lebih lanjut, Informasi Pribadi Anda juga dapat disimpan atau diolah di luar
                                negara Anda oleh personel Kami yang bekerja untuk Kami di negara lain, atau oleh penyedia
                                layanan pihak ketiga, vendor, pemasok, mitra, kontraktor, atau afiliasi Kami.
                                Kami juga akan mentransfer Informasi Pribadi Anda ke mitra usaha Kami yang berada di
                                negara lain, dengan tujuan untuk memastikan bahwa Anda dapat menikmati layanan Kami
                                atau penggunaan lain sesuai Kebijakan Privasi ini.<br/>
                                <br/>
                                Anda memahami dan menyetujui transfer Informasi Pribadi Anda keluar dari negara asal
                                Anda dan/atau Negara Asal sebagaimana dijelaskan dalam Kebijakan Privasi ini.<br/>
                                <br/>
                                14. Pemasaran dan Komunikasi<br/>
                                <br/>
                                Kami dan mitra Kami (termasuk vendor, pemasok, pedagang dan/atau pihak ketiga lainnya
                                yang berhubungan dengan Kami untuk kepentingan kegiatan komersial dan/atau
                                non-komersial) dapat mengirimkan Anda pemasaran langsung, iklan, dan komunikasi
                                promosi melalui panggilan telepon, layanan pesan singkat (SMS), email dan pemberitahuan
                                sehubungan dengan Platform Kami (“Pemasaran Komunikasi”).<br/>
                                <br/>
                                Anda dapat menerima pemasaran komunikasi dari Kami jika Anda:<br/>
                                <br/>
                                - Telah meminta informasi tersebut dari Kami;<br/>
                                <br/>
                                - Menggunakan Platform atau Layanan kami;<br/>
                                <br/>
                                - Memberitahukan detail Anda Ketika Anda memasuki sebuah kompetisi; atau<br/>
                                <br/>
                                - Anda terdaftar untuk promosi.<br/>
                                <br/>
                                Anda dapat memilih untuk tidak menerima komunikasi pemasaran tersebut kapan saja
                                dengan memilih opsi berhenti berlangganan yang tersedia dalam pesan terkait atau dapat
                                menghubungi Kami. Jika Anda memilih untuk keluar, Kami masih dapat mengirimi Anda
                                pesan-pesan non-promosi.<br/>
                                <br/>
                                15. Situs Web Pihak Ketiga dan tautan nya<br/>
                                <br/>
                                Platform Kami dan Pemasaran komunikasi pada Platform Kami dapat berisi tautan ke situs
                                web yang dioperasikan oleh pihak ketiga. Kami tidak mengendalikan atau menerima
                                pertanggungjawaban atau tanggung jawab untuk situs web ini dan untuk pengumpulan,
                                penggunaan, pemeliharaan, berbagi, atau pengungkapan data dan informasi oleh pihak
                                ketiga tersebut. Silakan baca syarat dan ketentuan dan kebijakan privasi dari situs web pihak
                                ketiga tersebut untuk mengetahui bagaimana mereka mengumpulkan dan menggunakan
                                Informasi Pribadi Anda.<br/>
                                <br/>
                                Jika Anda memiliki kekhawatiran tentang penanganan atau perlakuan Kami terhadap
                                Informasi Pribadi Anda atau jika Anda yakin bahwa privasinya telah dilanggar, Anda dapat
                                menghubungi Kami melalui kontak yang Kami sebutkan dalam Kebijakan Privasi ini. Lebih
                                lanjut, Kami akan menyelidiki keluhan Anda dan berupaya untuk memberikan tanggapan
                                terhadap keluhan tersebut dalam kurun waktu yang wajar setelah menerima keluhan yang
                                disampaikan oleh Anda.Kami berupaya secara wajar untuk melindungi setiap Informasi
                                Pribadi dan/atau data transaksi Anda. Kami senantiasa berusaha meningkatkan standar
                                perlindungan Platform Kami.<br/>
                                <br/>
                                16. Kontak Kami<br/>
                                <br/>
                                Jika Anda memiliki pertanyaan atau keluhan sehubungan dengan Kebijakan Privasi ini atau
                                jika Anda membutuhkan informasi tambahan, silakan hubungi kami di 021 6335252 atau Whatsapp +62 811-1380-5332<br/>
                                <br/>
                                17. Hak Anda sebagai Pengguna<br/>
                                <br/>
                                - Perbaikan - memperbaiki data pribadi yang tidak lengkap atau tidak akurat, dan juga
                                memperbaharui data pribadi yang Kami proses mengenai Anda.<br/>
                                <br/>
                                - Historis Informasi - meminta salinan data pribadi yang kami proses mengenai Anda, dimana
                                kami akan memberikan data tersebut kepada Anda dalam bentuk elektronik. Untuk
                                kepentingan privasi dan keamanan Anda sendiri, kami memiliki diskresi untuk meminta Anda
                                untuk membuktikan identitas Anda sebelum kami memberikan data yang dimintakan.<br/>
                                <br/>
                                - Penghapusan - meminta kami menghapus data pribadi yang kami proses mengenai Anda
                                sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.<br/>
                                <br/>
                                - Kerahasiaan - menentukan apabila ada data pribadi yang dikumpulkan yang harus
                                diperlakukan sebagai rahasia.<br/>
                                <br/>
                                Kami berhak menolak permintaan Anda terhadap akses pada, koreksi dari dan/atau
                                penghapusan terhadap, sebagian atau semua Informasi Pribadi Anda yang Kami kuasai atau
                                kendalikan jika diizinkan atau diperlukan berdasarkan Peraturan Perundang-undangan yang
                                Berlaku. Hal ini termasuk jika:<br/>
                                <br/>
                                - Kami perlu menyimpan data tersebut untuk mematuhi suatu kewajiban hukum atau untuk
                                membuat, menjalankan, atau membela suatu klaim hukum;<br/>
                                <br/>
                                - dalam keadaan di mana Informasi Pribadi tersebut dapat berisi referensi kepada orang lain
                                atau di mana permintaan untuk akses atau permintaan untuk mengoreksi atau menghapus
                                adalah untuk alasan yang Kami anggap tidak relevan, tidak serius, atau menyulitkan, dengan
                                tunduk pada Peraturan Perundang-undangan yang Berlaku, Kami mencadangkan hak untuk
                                membebankan biaya administratif untuk setiap permintaan akses dan/atau koreksi dimaksud.
                                Dan Apabila Anda ingin Informasi Pribadi Anda dihapus, mohon hubungi Kami melalui alamat
                                surel Kami di atas dan Kami akan melakukan seluruh langkah wajar untuk menghapusnya
                                kecuali apabila kami perlu untuk menyimpannya dikarenakan alasan-alasan hukum. Jika
                                diizinkan secara hukum, Kami dapat membebankan biaya yang wajar atas pemenuhan hak
                                yang Anda minta.<br/>
                                <br/>
                                18. Bahasa<br/>
                                <br/>
                                Untuk memenuhi kewajiban berdasarkan hukum apapun yang berlaku sepanjang hukum
                                yang berlaku tersebut mengharuskan bahwa Kebijakan Privasi ini dibuat dalam suatu bahasa
                                setempat (contohnya bahasa Indonesia), Kebijakan Privasi ini dibuat dalam versi bahasa
                                Indonesia dan versi bahasa Inggris. Apabila ada suatu inkonsistensi atau perbedaan
                                interpretasi antara versi bahasa Indonesia dan versi bahasa Inggris, maka versi bahasa
                                Indonesia akan berlaku dan versi bahasa Inggris akan dianggap secara otomatis diubah
                                (dengan keberlakuan sejak tanggal berlaku Kebijakan Privasi ini) untuk membuat bagian dari
                                versi bahasa Inggris konsisten dengan bagian yang relevan dari versi bahasa Indonesia.<br/>
                                <br/>
                                19. Anak Dibawah Umur<br/>
                                <br/>
                                Platform Kami tidak ditujukan untuk anak-anak di bawah usia 16 tahun dan Kami tidak dapat
                                membedakan usia orang yang karena mengakses dan mengunjunginya sama. Jika anak di
                                bawah umur (menurut undang-undang yang berlaku) telah menyediakan Informasi Pribadi
                                kepada Kami tanpa persetujuan orang tua atau wali, orang tua atau wali harus menghubungi
                                Kami dan atau dari kami tanpa harus persetujuan dari orang tua atau wali untuk menghapus
                                Informasi Pribadi yang relevan dan berhenti berlangganan di bawah umur.<br/>
                                <br/>
                                20. Penyelesaian Sengketa dan Hukum<br/>
                                <br/>
                                Kebijakan Privasi ini diatur dan ditafsirkan menurut hukum yang berlaku di negara Republik
                                Indonesia dan setiap sengketa yang timbul dari Kebijakan Privasi ini akan diselesaikan
                                terlebih dahulu secara musyawarah antara Anda dengan Kami. Dalam hal 60 (enam puluh)
                                hari kalender sejak musyawarah pertama kali dilakukan, masih belum mufakat, maka
                                perselisihan dapat diselesaikan melalui arbitrase yang diadakan di Jakarta sesuai dengan
                                Peraturan Arbitrase yang berlaku di Badan Arbitrase Nasional.
                            </div>
                        </div>
                    </div>
                </section>

                <ModuleNewsletter />

            </React.Fragment>
        );
    }
}

export default PrivacyPolicy;